export function find() {
  return {
    code: "OK",
    message: "La operación se completó exitosamente.",
    data: {
      id: 1,
      name: "Prestamático",
      order: 0,
      active: true,
      steps: [
        {
          id: 1,
          name: "Datos de contacto",
          details: "",
          order: 1,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 1,
              name: "Ingresa tu móvil a 9 digitos",
              key: "phone",
              type: "string",
              component: "phone",
            },
          ],
          filters: [],
        },
        {
          id: 3,
          name: "",
          details: "",
          order: 3,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 3,
              name: "Correo electrónico",
              key: "email",
              type: "string",
              component: "email",
            },
          ],
          filters: [],
        },
        {
          id: 4,
          name: "",
          details: "Para continuar acepta los siguientes términos:",
          order: 4,
          step: 1,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 4,
              name: "Acepto los términos y condiciones del servicio",
              key: "termsandconditions",
              type: "boolean",
              component: "switch",
            },
            {
              id: 5,
              name: "Acepto la política de privacidad",
              key: "privacyNotice",
              type: "boolean",
              component: "switch",
            },
          ],
          filters: [],
        },
        {
          id: 5,
          name: "",
          details: "",
          order: 1,
          step: 2,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 6,
              name: "¿Cuál es tu ingreso mensual?",
              key: "incomeId",
              type: "string",
              component: "radiobutton",
              catalog: "income",
            },
          ],
          filters: [],
        },
        {
          id: 6,
          name: "",
          details: "",
          order: 2,
          step: 2,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 7,
              name: "¿Puedes comprobar tus ingresos?",
              key: "checkIncome",
              type: "boolean",
              component: "yesnobutton",
            },
          ],
          filters: [],
        },
        {
          id: 7,
          name: "",
          details: "",
          order: 1,
          step: 3,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 8,
              name: "¿Cuál es tu estatus laboral actualmente?",
              key: "professionId",
              type: "string",
              component: "radiobutton",
              catalog: "profession",
            },
          ],
          filters: [],
        },
        {
          id: 8,
          name: "",
          details: "",
          order: 1,
          step: 4,
          maxAnswers: 1,
          required: false,
          answers: [
            {
              id: 9,
              name: "¿Actualmente tienes uno o más de estos productos financieros vigentes?",
              key: "ProductId",
              type: "string",
              component: "switch",
              catalog: "product",
            },
          ],
          filters: [],
        },{
          id: 9,
          name: "",
          details: "",
          order: 1,
          step: 4,
          maxAnswers: 1,
          required: false,
          answers: [
            {
              id: 10,
              name: "¿Estás al corriente con tu crédito o préstamo?",
              key: "paymentUpToDate",
              type: "boolean",
              component: "yesnobutton",
            },
          ],
        },
        {
          id: 10,
          name: "",
          details: "",
          order: 1,
          step: 5,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 11,
              name: "Fecha de nacimiento",
              key: "birthdate",
              type: "date",
              component: "date",
            },
          ],
          filters: [],
        },
        {
          id: 11,
          name: "",
          details: "",
          order: 2,
          step: 5,
          maxAnswers: 1,
          required: true,
          answers: [
            {
              id: 12,
              name: "¿Dónde vives?",
              key: "stateId",
              type: "string",
              component: "dropdown",
              catalog: "state",
            },
          ],
          filters: [],
        },
      ],
    },
  };
}
